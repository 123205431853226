'use client';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import qs from 'query-string';
import React from 'react';
import store from 'store';
import { useReadLocalStorage } from 'usehooks-ts';
import { buttonVariants } from './button';

interface DetailModalLinkProps {
  pathname: string;
  children: React.ReactNode;
  className?: string;
  ignoreDefaultClassName?: boolean;
  query?: { page: string; im: boolean };
  target?: React.HTMLAttributeAnchorTarget;
}

export const DETAIL_MODAL_SOURCE_KEY = 'detail_modal_source';
export const BULK_MODAL_SOURCE_KEY = 'bulk_modal_source';
export const CONTRACT_MODAL_SOURCE_KEY = 'contract_modal_source';
export const INVOICING_MODAL_SOURCE_KEY = 'invoicing_modal_source';

type IOverrides =
  | typeof CONTRACT_MODAL_SOURCE_KEY
  | typeof BULK_MODAL_SOURCE_KEY
  | typeof INVOICING_MODAL_SOURCE_KEY;

type IUrlStringOrhMethod = string | ((id?: string) => string);

export const useOpenDetailModal = (
  urlStringOrMethod: IUrlStringOrhMethod,
  overrideKey?: IOverrides,
  ignoreDetailPageBlock?: boolean
) => {
  const router = useRouter();
  const currentPathname = usePathname();
  const query = useSearchParams().toString();
  const scroll = useReadLocalStorage<number>('scrollPosition') || null;
  const isDetailPage = currentPathname.includes('/detail/');
  const setDetailModalSourcePage = () => {
    if (isDetailPage && !ignoreDetailPageBlock) return;
    const parsedQuery = qs.parse(query);
    const newQuery = qs.stringify(
      { ...parsedQuery, scroll: scroll },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    store.set(
      overrideKey ?? DETAIL_MODAL_SOURCE_KEY,
      `${currentPathname}?${newQuery}`
    );
  };

  const navigate = (id?: string) => {
    let url: string;
    if (typeof urlStringOrMethod === 'function') {
      url = urlStringOrMethod(id);
    } else if (typeof urlStringOrMethod === 'string') {
      url = urlStringOrMethod;
    } else {
      return;
    }

    setDetailModalSourcePage();
    router.push(url);
  };

  return navigate;
};

export const DetailModalLink = React.forwardRef<
  HTMLAnchorElement,
  DetailModalLinkProps
>(
  (
    { pathname, children, className, target, ignoreDefaultClassName, ...props },
    ref
  ) => {
    const currentPathname = usePathname();
    const query = useSearchParams().toString();

    const isDetailPage = currentPathname.includes('/detail/');
    const setDetailModalSourcePage = () => {
      if (isDetailPage) return;

      store.set(DETAIL_MODAL_SOURCE_KEY, `${currentPathname}?${query}`);
    };

    if (!children) return '-';

    return (
      <Link
        prefetch={false}
        onClick={setDetailModalSourcePage}
        target={target}
        ref={ref}
        {...props}
        className={cn(
          ignoreDefaultClassName
            ? className
            : buttonVariants({ variant: 'link', size: 'sm' }),
          'h-auto whitespace-nowrap px-0',
          className
        )}
        href={{
          pathname,
        }}
      >
        {children}
      </Link>
    );
  }
);

DetailModalLink.displayName = 'DetailModalLink';
