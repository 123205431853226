import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { forwardRef } from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-normal leading-4 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'border border-primary bg-primary text-primary-foreground shadow-[0px_2px_4px_primary] shadow-primary/10 hover:bg-primary/80 active:scale-[0.99] active:shadow-none ',
        destructive:
          'border border-destructive text-destructive hover:bg-destructive/30',
        outline:
          'border border-input shadow-[0px_2px_4px_rgba(85,85,85,0.04)] hover:bg-accent hover:text-accent-foreground active:scale-[0.99]',
        secondary:
          'border border-secondary bg-secondary text-secondary-foreground hover:bg-secondary/80 dark:text-foreground',
        ghost: 'hover:bg-accent hover:text-foreground',
        link: 'text-link underline-offset-4 hover:underline',
      },
      size: {
        default: 'px-4 py-2',
        sm: 'rounded-md px-3 py-2',
        lg: 'h-11 rounded-md px-8',
        icon: 'p-1',
        'icon-sm': 'p-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
